<template>
    <v-dialog v-model="dialog" @keydown.esc="close" scrollable max-width="600" transition="dialog-bottom-transition">
        <v-card v-if="point">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="close">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ point.t }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text style="background: white">
                <v-alert text dense color="secondary" icon="info" v-if="point.d" class="mt-4">
                    {{ point.d }}
                </v-alert>

                <v-alert text dense color="secondary" icon="directions" v-if="point.address" class="mt-4">
                    <div v-html="formatted(point.address)"></div>
                    <strong>Cidade:</strong> {{ this.point.city }}
                </v-alert>

                <v-alert text color="secondary" icon="phone" v-if="point.c">
                    {{ point.c }}
                    <v-btn class="mx-2 green" dark x-small :href="contactHref" target="_blank">
                        <v-icon size="24px" small>fab fa-whatsapp</v-icon>
                    </v-btn>

                </v-alert>

                <v-alert text color="secondary" icon="info" v-if="point.available > 0 ||
                    point.charging > 0 ||
                    point.connected > 0 ||
                    point.offline > 0 ||
                    point.availableOthers > 0 ||
                    point.chargingOthers > 0 ||
                    point.connectedOthers > 0 ||
                    point.offlineOthers > 0 ||
                    point.availableBike > 0 ||
                    point.chargingBike > 0 ||
                    point.connectedBike > 0 ||
                    point.offlineBike > 0
                ">
                    <v-layout wrap>
                        <v-flex xs6>
                            <div v-if="point.available > 0 || point.availableOthers > 0 || point.availableBike > 0">
                                Disponíveis:
                                <strong>{{ point.available + point.availableOthers + point.availableBike }}</strong>
                            </div>
                            <div v-if="point.charging > 0 || point.chargingOthers > 0 || point.chargingBike > 0">
                                Carregando:
                                <strong>{{ point.charging + point.chargingOthers + point.chargingBike }}</strong>
                            </div>
                            <div v-if="point.connected > 0 || point.connectedOthers > 0 || point.connectedBike > 0">
                                Conectado:
                                <strong>{{ point.connected + point.connectedOthers + point.connectedBike }}</strong>
                            </div>
                            <div v-if="point.offline > 0 || point.offlineOthers > 0 || point.offlineBike > 0">
                                Offline:
                                <strong>{{ point.offline + point.offlineOthers + point.offlineBike }}</strong>
                            </div>
                        </v-flex>
                        <v-flex xs6 v-if="point.chargers.length > 0" class="text-center">
                            <b>Carregadores:</b>
                            <div v-for="(charger, i) in point.chargers" :key="i">
                                <router-link :to="{ path: nowPath(charger) }">
                                    <v-btn x-small style="width: 100%" :class="nowColor(charger.status)" :key="i" v-if="charger.status">
                                        {{ charger.name }}
                                        <v-spacer></v-spacer>
                                        <v-icon right>visibility</v-icon>
                                    </v-btn>
                                </router-link>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-alert>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header color="grey lighten-3">
                            <v-icon left>date_range</v-icon>
                            <strong>Horários de Funcionamento:</strong>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="grey lighten-3">
                            <v-list dense>
                                <v-list-item v-for="(day, index) in point.schedule" :key="index">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <strong>{{ translateDay(day.day) }}: </strong>
                                            <span v-if="day.openOn && day.closeOn">{{ day.openOn }} - {{ day.closeOn }}</span>
                                            <span v-else>Fechado</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>


            <v-card-actions style="background: grey">
                <v-layout wrap>
                    <v-flex sm6 xs12  class="pa-1">
                        <v-btn color="white black--text" dark @click="navegate" block>
                            <v-img aspect-ratio="1" max-height="23" max-width="120" :src="require('@/assets/images/googleMaps.png')" />
                        </v-btn>
                    </v-flex>
                    <v-flex sm6 xs12  class="pa-1">
                        <v-btn color="#05c8f7" dark @click="navegateWaze" block>
                            <v-icon left>fab fa-waze</v-icon>
                            Waze
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import statusCharger from "@/helpers/StatusCharger";

export default {
    name: "PointDetails",

    props: {
        point: {
            required: true,
        },
        show: {
            required: true,
        },
    },

    data() {
        return {
            statusCharger,
            dayTranslations: {
                Monday: "Segunda-feira",
                Tuesday: "Terça-feira",
                Wednesday: "Quarta-feira",
                Thursday: "Quinta-feira",
                Friday: "Sexta-feira",
                Saturday: "Sábado",
                Sunday: "Domingo",
            },
        };
    },

    computed: {
        dialog: {
            get: function () {
                return this.show
            },
            set: function (value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        },
        formatted() {
            return (text) => text.replace(/\n/g, "<br>");
        },
        contactHref() {
            return encodeURI(`https://wa.me/55${this.point.c.replace(/\D/g, "")}?text=Estou na estação ${this.point.t} e gostaria de `);
        },
        nowPath() {
            return (charger) => (charger.eBike ? "ebike/" + charger.name : "now/" + charger.name);
        },
        nowColor() {
            return (status) => {
                const color = this.statusCharger.getComponentStyle(status);
                return `${color.color} ${color.secondColor}--text`;
            };
        },
    },

    methods: {
        translateDay(day) {
            return this.dayTranslations[day] || day;
        },
        navegate() {
            window.open("https://maps.google.com/maps?daddr=" + this.point.cord[0] + "," + this.point.cord[1] + "&amp;ll=");
        },
        navegateWaze() {
            window.open("https://www.waze.com/ul?ll=" + this.point.cord[0] + "," + this.point.cord[1] + "&navigate=yes");
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>