<template>
    <div v-if="componentStyle.status">
        <v-card dark :color="componentStyle.color" @click="selectPlug(plug.plug)">
            <v-container grid-list-xl>
                <v-layout wrap>
                    <v-flex xs6 sm3>
                        <v-chip class="mx-2 subtitle-2" :large="$vuetify.breakpoint.smAndUp" :color="componentStyle.secondColor" :text-color="componentStyle.color">
                            Plug {{ plug.plug }}
                            <v-icon right large class="mx-4"> electrical_services </v-icon>
                        </v-chip>
                    </v-flex>
                    <v-flex xs6 sm5>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <v-list-item-status :class="`${componentStyle.secondColor}--text headline`">
                                    <v-icon v-if="icon && $vuetify.breakpoint.smAndUp" class="mr-2" :color="componentStyle.secondColor"> {{ componentStyle.icon }}
                                    </v-icon> {{ componentStyle.status }}
                                </v-list-item-status>
                                <v-list-item-subtitle v-if="$vuetify.breakpoint.smAndUp" :class="`${componentStyle.secondColor}--text`">Status</v-list-item-subtitle>
                            </div>
                        </div>
                    </v-flex>
                    <v-flex xs12 sm4>
                        <v-btn block :color="`${componentStyle.secondColor} ${componentStyle.color}--text`" @click="selectPlug(plug.plug)"
                            :small="$vuetify.breakpoint.smAndDown">
                            Selecionar Plug<v-icon right> send </v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import statusCharger from "@/helpers/StatusCharger";

export default {
    props: {
        plug: {
            required: true,
        },
    },

    mounted() {
        this.componentStyle = this.statusCharger.getComponentStyle(this.plug.status);
    },

    data() {
        return {
            statusCharger,
            componentStyle: {
                status: null,
                icon: null,
                color: null,
                secondColor: null,
            }
        };
    },

    methods: {
        selectPlug(plug) {
            this.$emit("selectPlug", plug);
        },
    },

    watch: {
        plug: {
            immediate: true,
            handler() {
                this.componentStyle = this.statusCharger.getComponentStyle(this.plug.status);
            },
        },
    },
};
</script>
