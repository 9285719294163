import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import 'vuetify/src/styles/main.sass'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import store from '@/helpers/store'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'md' || 'fa'
    },
    theme: {
        options: {
            customProperties: true,
        },
        dark: store.getters.isDarkMode,
        themes: {
            light: {
                primary: '#FF5A60',
                secondary: '#616162',
                accent: '#49BAD3',
                error: '#FF5A60',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#ffd219',
                critical: '#FF0000'
            },
            dark: {
                primary: '#FF5A60',
                secondary: '#616162',
                accent: '#49BAD3',
                error: '#FF5A60',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#ffd219',
                critical: '#FF0000'
            }
        }
    }
})