<template>
    <div>
        <v-alert dense text type="success" border="left" class="my-0" v-if="plug.permissionToPlug == 'USER_OK'">
            Associado ao seu usuário
        </v-alert>
        <v-alert dense text type="success" border="left" class="my-0" v-if="plug && plug.unlockBy">
            {{ unlockByText(plug.unlockBy) }}
        </v-alert>
        <v-alert dense text type="success" border="left" class="my-0" v-if="plug && plug.userEmail">
            Reconhecido por usuário: {{ plug.userEmail }}
        </v-alert>
        <v-alert dense text type="error" border="left" class="my-0" v-if="showStoppedByUser">
            Parado pelo Usuário
        </v-alert>
        <v-alert dense text type="error" border="left" class="my-0" v-if="plug && plug.payment && plug.payment != null && plug.payment != 'PROCCESS_OK'">
            {{ paymentText(plug.payment) }}
        </v-alert>

        <v-card :color="componentStyle.color" dark v-if="componentStyle.color">
            <v-card-text :class="`${componentStyle.secondColor}--text`" v-if="componentStyle.status">
                <div class="headline mb-sm-2">
                    <v-icon :color="componentStyle.secondColor">{{ componentStyle.icon }}</v-icon>
                    {{ componentStyle.status }}
                </div>
                Status
            </v-card-text>

            <ButtonPlug v-if="plug.payment != 'WAIT_PLUG' || plug.status != 'Available'" :chargerName="chargerName" :charger="charger" :plug="plug" :color="componentStyle.color" :secondColor="componentStyle.secondColor" />

            <v-card-text :class="`${componentStyle.secondColor}--text`" v-if="plug.unlockBy == 'PAY' && charger.priceKwh > 0 && plug.consumption > 0 && statusCharger.isOccupied(plug.status)">
                <div class="headline mb-sm-2">
                    <v-icon :color="componentStyle.secondColor">payment</v-icon>
                    R$ {{ Number.parseFloat(plug.consumption * charger.priceKwh).toFixed(2) }}
                </div>
                Valor parcial
            </v-card-text>
            <v-card-text :class="`${componentStyle.secondColor}--text`" v-if="plug.consumption != null && plug.consumption >= 0 && statusCharger.isOccupied(plug.status)">
                <div class="headline mb-sm-2">
                    <v-icon :color="componentStyle.secondColor">battery_charging_full</v-icon>
                    {{ plug.consumption }} kWh
                </div>
                Energia consumida
            </v-card-text>
            <v-card-text :class="`${componentStyle.secondColor}--text`" v-if="plug.percent != null && plug.percent > 0 && statusCharger.isOccupied(plug.status)">
                <div class="headline mb-sm-2">{{ plug.percent }} %</div>
                Porcentagem da bateria
            </v-card-text>
            <v-card-text :class="`${componentStyle.secondColor}--text`" v-if="plug.maximumPower != null && plug.maximumPower > 0 && statusCharger.isOccupied(plug.status)">
                <div class="headline mb-sm-2">
                    <v-icon :color="componentStyle.secondColor">speed</v-icon>
                    {{ plug.maximumPower > 1000 ? `${Math.round(plug.maximumPower / 10) / 100} kW` : `${plug.maximumPower} Watts` }}
                </div>
                Potência máxima
            </v-card-text>
            <v-card-text :class="`${componentStyle.secondColor}--text`" v-if="plug.power != null && plug.power >= 0 && plug.status == 'Charging'">
                <div class="headline mb-sm-2">
                    <v-icon :color="componentStyle.secondColor">flash_on</v-icon>
                    {{ plug.power > 1000 ? `${Math.round(plug.power / 10) / 100} kW` : `${plug.power} Watts` }}
                </div>
                Potência instantânea
            </v-card-text>
            <Time :minutesConnected="plug.minutesConnected" :secondColor="componentStyle.secondColor" title="Tempo conectado" :show="statusCharger.isOccupied(plug.status)" />
            <Time :minutesConnected="plug.minutesCharger" :secondColor="componentStyle.secondColor" title="Tempo carregando" :show="statusCharger.isOccupied(plug.status)" />
        </v-card>
        <Tips :status="plug.status" :polity="charger.polity" :isPay="charger.price > 0" />
    </div>
</template>

<script>
import dateTime from "@/helpers/DateTime";
import statusCharger from "@/helpers/StatusCharger";
import ButtonPlug from "./ButtonPlug";
import Tips from "./Tips";
import Time from "./Time";

export default {
    components: { ButtonPlug, Tips, Time },

    props: {
        chargerName: {
            required: true,
        },
        charger: {
            required: true,
        },
        plug: {
            required: true,
        },
    },

    data() {
        return {
            statusCharger,
            dateTime,
            componentStyle: {
                status: null,
                icon: null,
                color: null,
                secondColor: null,
            }
        };
    },

    computed: {
        showStoppedByUser() {
            return this.plug && this.statusCharger.isOccupied(this.plug.status) && this.plug.stoppedForced;
        },
    },

    methods: {
        unlockByText(unlockBy) {
            switch (unlockBy) {
                case "FREE_POWER":
                    return this.charger.price > 0 ? "Aguardando pagamento para liberação de potência." : "Liberado com baixa potência, aguardando ativação do usuário."
                case "APP":
                    return "Liberado por aplicativo"
                case "NFC":
                    return "Liberado por cartão NFC"
                case "PAY":
                    return "Liberado por pagamento"
                default:
                    return "-";
            }
        },
        paymentText(payment) {
            if (payment == "WAIT_START") {
                return "Pagamento feito com sucesso, aguardando carregador iniciar."
            } else if (payment == "WAIT_PLUG") {
                return "Pagamento feito com sucesso, conecte o carro no carregador."
            } else if (payment == "WAIT_UNLOCK") {
                return "Pagamento feito com sucesso, aguardando desbloqueio do carregador."
            } else {
                return `Erro ao efetuar transação com a operadora do cartão: ${payment}`;
            }
        }
    },

    watch: {
        plug: {
            immediate: true,
            handler() {
                this.componentStyle = { ...this.statusCharger.getComponentStyle(this.plug.status) };
            },
        },
    },
}
</script>
