<template>
    <v-app id="create">
        <v-banner v-if="deferredPrompt" color="secondary" dark class="text-left elevation-24 pt-12 pt-md-0">
            Para instalar o aplicativo no seu dispositivo basta clicar no botão 'Instalar'.
            <br>
            <v-btn color="green" @click="install" small class="mt-4">Instalar</v-btn>
            <v-btn text @click="dismiss" small class="mt-4">Cancelar</v-btn>
            <template v-slot:actions>

            </template>
        </v-banner>

        <Privacy v-if="$store.getters.privacyValid == false" />
        <Login />
        <Menu />
        <Message />

        <v-dialog v-model="$store.getters.isOffline" persistent max-width="290">
            <v-card color="primary">
                <v-card-text class="text-center pt-8">
                    <v-icon x-large color="white">cloud_off</v-icon>
                </v-card-text>
                <v-card-text class="headline white--text">Você está OFFLINE :(</v-card-text>
                <v-card-text>Reconecte na internet para continuar. ({{ $store.state.offlineCounter }}) </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showUpdate" max-width="350">
            <v-card color="success">
                <v-card-text class="text-center pt-8">
                    <v-icon x-large color="white">update</v-icon>
                </v-card-text>
                <v-card-text class="headline white--text">{{ $t("Atualizamos o sistema.") }}</v-card-text>
                <v-card-text>{{ $t("Estamos sempre buscando novas formas de melhorar sua experiência.") }}</v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn @click="showUpdate = false" dark small outlined>{{ $t("Fechar") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <transition name="body">
            <router-view></router-view>
        </transition>

        <v-btn color="green" fab dark  @click="info.show = !info.show" fixed right bottom class="elevation-24 d-print-none" style="bottom: 2px; right: 2px;">
            <v-icon> info </v-icon>
        </v-btn>

        <Info :show="info.show" @onClose="closeInfo()" />
        <Loading v-if="$store.getters.isLoading" />
        <Settings />
    </v-app>
</template>

<script>
import Message from "@/components/core/Message";
import Privacy from "@/components/shared/PrivacyModal";
import Loading from "@/components/core/Loading";
import Login from "@/components/core/Login";
import Settings from "@/components/core/Settings";
import Menu from "@/components/shared/Menu";
import Info from "@/components/shared/Info";

export default {
    name: "App",

    components: { Menu, Message, Loading, Privacy, Info, Login, Settings },

    data() {
        return {
            deferredPrompt: null,
            offline: false,
            showUpdate: false,
            timer: null,
            info: {
                show: false,
            },
        };
    },

    mounted() {
        this.$store.commit("START");
        this.showUpdate = localStorage.getItem("newVersion") ? true : false;
        localStorage.removeItem("newVersion");
    },

    created() {
        this.timer = setInterval(this.verify, 1000);
        window.addEventListener("beforeinstallprompt", (e) => {
            if (!this.$store.getters.isCancelledInstall) {
                e.preventDefault();
                this.deferredPrompt = e;
            }
        });
        window.addEventListener("appinstalled", () => {
            this.deferredPrompt = null;
        });
    },

    methods: {
        closeInfo() {
            this.info.show = false;
        },
        async dismiss() {
            this.deferredPrompt = null;
            this.$store.commit("CANCEL_INSTALL");
        },
        async install() {
            this.deferredPrompt.prompt();
        },
        verify() {
            this.$store.dispatch("checkConnection");
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>

<style>
.v-btn {
    text-transform: none !important;
}

.critical {
    animation: blinking-animation 1.5s infinite; /* Reduzida para 1.5s */
}

@keyframes blinking-animation {
    0%, 80% {
        opacity: 1; /* Texto visível por 50% do tempo */
    }
    85%, 100% {
        opacity: 0; /* Texto piscando rapidamente */
    }
}
</style>